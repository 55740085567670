import React from "react";
import Container from "../../layout/Container";
import SocialIcons from "./SocialIcons";

const Footer = () => {
	return (
		<section className="border border-x-0 border-b-0 border-[#495057] py-[60px]">
			<Container className="sm:max-w-[100%] lg:px-2">
				<div className="flex gap-6 py-8 md:flex-col md:text-center">
					<div className="px-2.5 space-y-3">
						<h3 className="font-bold text-[#dee2e6]">
							StrongHands
						</h3>
						<p>
							A traditional community founded in 2015 made by
							people who are engaged to make cryptocurrency a new
							economy for everyone.
						</p>
						<p className="text-sm lg:hidden leading-6 text-[#dee2e6]">
							© 2022 StrongHands – All rights reserved.
						</p>
					</div>
					<div className="px-2.5 flex-shrink-0 inline-flex flex-col gap-4 md:hidden">
						<h3 className="font-bold text-[#dee2e6]">Sections</h3>
						<div className="flex flex-col gap-4">
							<a href="/#aboutus">
								<span>About Us</span>
							</a>
							<a href="/#ecosystem">
								<span>Ecosystem</span>
							</a>
							<a href="/#roadmap">
								<span>Roadmap</span>
							</a>
							<a href="https://stronghandsblockchain.medium.com/">
								<span>Dev Blog</span>
							</a>
							<a href="/#">
								<span>Litepaper</span>
							</a>
						</div>
					</div>
					<div className="px-2.5 flex-shrink-0 inline-flex flex-col gap-4 md:hidden">
						<h3 className="font-bold text-[#dee2e6]">Docs</h3>
						<div className="flex flex-col gap-4">
							<span>Medium Blog</span>
							<span>Token Audit Report</span>
						</div>
					</div>
					<div className="px-2.5 flex-shrink-0 inline-flex flex-col gap-4">
						<h3 className="font-bold text-[#dee2e6]">Contact Us</h3>
						<p>For questions and inquires</p>
						<a href="mailto:contact@stronghands.info">
							<p className="text-[#4ded54]">
								contact@stronghands.info
							</p>
						</a>
						<SocialIcons className="lg:hidden" />
					</div>
				</div>
				<div className="items-center justify-between hidden gap-4 lg:flex lg:px-2 md:flex-col-reverse">
					<p className="text-sm leading-6 text-[#dee2e6]">
						© 2022 StrongHands – All rights reserved.
					</p>
					<SocialIcons className="flex justify-center" />
				</div>
			</Container>
		</section>
	);
};

export default Footer;
