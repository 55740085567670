import "./App.css";
import About from "./components/About";
// import Ecosystem from "./components/Ecosystem";
import Footer from "./components/Footer";
import Header from "./components/Header";
// import Hero from "./components/Hero";
import JoinCommunityBanner from "./components/JoinCommunityBanner";
// import NFT from "./components/NFT";
// import OurPartner from "./components/OurPartner";
// import RoadMap from "./components/RoadMap";

function App() {
	return (
		<div className="bg-[#1A1F29] min-h-screen w-full">
			<Header />
			{/* <Hero /> */}
			<About />
			{/* <Ecosystem /> */}
			<JoinCommunityBanner />
			{/* <RoadMap /> */}
			{/* <OurPartner /> */}
			<Footer />
		</div>
	);
}

export default App;
