import Container from "../../layout/Container";
import clsx from "clsx";
import styles from "./styles.module.css";
import NFT from "../NFT";
import { useEffect, useState } from "react";

const About = () => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  return (
    <section id="aboutus" className="mt-1 pt-14">
      {/*      <Container className="flex items-center gap-16 py-6 lg:flex-col">
        <div
          className={clsx(
            "w-[58%] shrink-0 h-[416px] md:h-full md:max-w-[400px] md:w-full md:aspect-video",
            styles.player
          )}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube-nocookie.com/embed/NpEaa2P7qZI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="space-y-10 md:space-y-4 lg:w-1/2 md:w-full lg:text-center">
          <h2 className="text-[32px] leading-[38px] font-medium lg:text-2xl md:text-sm md:leading-snug lg:leading-relaxed">
            The New <br className="lg:hidden" />
            CrossChain Evolution
          </h2>
          <p className="md:text-sm md:leading-snug">
            Our platform is developed to be one of the best AMM and Bridge for
            many projects, with StrongHands Finance you will save money with
            fees changing networks into an easy, trustless and intuitive
            process.
          </p>
        </div>
      </Container> */}
      {/*       <Container className="flex items-center gap-16 py-6 lg:gap-4 xl:gap-8 md:flex-col sm:flex-col-reverse">
        <div className="w-[58%] px-16 lg:px-0 xl:w-1/2 md:w-full">
          <div className="border-l-[5px] border-[#4DED54] lg:border-0 pl-5 md:px-4 space-y-6">
            <h2 className="text-[32px] leading-tight font-medium md:text-xl md:text-center">
              Our Bridge Solution
            </h2>
            <p>
              An increasing demand from our community, inspired us to move to a
              Defi Ecosystem by integrating our 1st gen blockchains to a
              descentralized protocol which connects to the most popular
              networks as BNB Chain, Polygon Network, Ethereum, Tron and others.
            </p>
            <p>
              Our project aims to make token trading easy to newbie users, while
              improving efficiency of trading versus CEX exchanges.
            </p>
          </div>
        </div>
        <div className="xl:flex-shrink-0 xl:w-1/2 md:w-64 lg:pr-5 md:pr-0">
          <img
            src="/icons/integration-v2-sm.svg"
            alt="bridge solution"
            className="md:mx-auto"
          />
        </div>
      </Container> */}

      <NFT />

      <div className="w-4/5 mx-auto max-w-new">
        <a
          href="https://bridge.stronghands.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          {screenSize.dynamicWidth > 1024 ? (
            <img src="/images/bridge-lg.png" alt="bridge-lg" />
          ) : screenSize.dynamicWidth < 1024 && screenSize.dynamicWidth > 600 ? (
            <img src="/images/bridge-md.png" alt="bridge-md" />
          ) : (
            <img src="/images/bridge-sm.png" alt="bridge-sm" />
          )}
        </a>
      </div>
    </section>
  );
};

export default About;
