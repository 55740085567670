import React, { useState, useEffect } from "react";
import Container from "../../layout/Container";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import NFTCard from "./Card";

const NFT = () => {
  const { ethereum } = window;
  const [currentAccount, setCurrentAccount] = useState("");

  const CONTRACT_ADDRESS = "0xfeA93c866a4A9dc8CE223e054f0DefB37F9A2b3F";
  const CONTRACT_ADDRESS2 = "0x10F4f0197E92b6e3a7e7f83675879C7c91f4afCD";
  const CONTRACT_ADDRESS3 = "0xb7c7f5bC29ce8C1BF6bf9089D8DDf833d43f298A";


  const checkIfWalletIsConnected = async () => {
    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    } else {
      console.log("We have the ethereum object", ethereum);
    }

    /*
     * Check if we're authorized to access the user's wallet
     */
    const accounts = await ethereum.request({ method: "eth_accounts" });
    let chainId = await ethereum.request({ method: "eth_chainId" });
    console.log("Connected to chain " + chainId);

    // String, hex code of the chainId of the Rinkebey test network
    const rinkebyChainId = "0x1";
    if (chainId !== rinkebyChainId) {
      alert("You are not connected to the Ethereum Network!");
    }

    /*
     * User can have multiple authorized accounts, we grab the first one if its there!
     */
    if (accounts.length !== 0) {
      const account = accounts[0];
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  };

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      /*
       * Fancy method to request access to account.
       */
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let chainId = await ethereum.request({ method: "eth_chainId" });

      // String, hex code of the chainId of the Rinkebey test network
      const rinkebyChainId = "0x1";
      if (chainId !== rinkebyChainId) {
        alert("You are not connected to the Rinkeby Test Network!");
      }

      /*
       * Boom! This should print out public address once we authorize Metamask.
       */
      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentAccount) {
      checkIfWalletIsConnected();
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  return (
    <Container className="py-20">
      <h2 className="text-[56px] leading-[84px] font-light md:text-[36px] md:leading-snug text-center">
        NFT Drops
      </h2>
      {currentAccount === "" ? (
        <>
          <button
            onClick={connectWallet}
            className="cta-button connect-wallet-button bg-[#EC7272] text-black rounded-full m-auto p-3 text-center flex justify-center"
          >
            Connect to Wallet
          </button>
        </>
      ) : (
        <>
          <button className="cta-button connect-wallet-button bg-[#76BA99] text-black rounded-full m-auto p-3 text-center flex justify-center">
            {" "}
            Connected
          </button>
        </>
      )}

      <div className="flex items-center gap-4 mt-16 md:flex-col">
        <NFTCard
          videoUrl="/video/Manekin-Black-common.mp4"
          name="Manekin Black"
          volume={0.05}
          hasLiked={false}
          CONTRACT_ADDRESS={CONTRACT_ADDRESS3}
          currentAccount={currentAccount}
        />
        <NFTCard
          videoUrl="/video/Manekin-Gold-rare.mp4"
          name="Manekin Gold"
          volume={0.05}
          hasLiked={false}
          CONTRACT_ADDRESS={CONTRACT_ADDRESS2}
          currentAccount={currentAccount}
        />
        <NFTCard
          videoUrl="/video/Manekin-Neko-epic.mp4"
          name="Manekin Neko"
          hasLiked={false}
          volume={0.083}
          CONTRACT_ADDRESS={CONTRACT_ADDRESS}
          currentAccount={currentAccount}
        />
      </div>
    </Container>
  );
};

export default NFT;
