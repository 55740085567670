import React from "react";
import Container from "../../layout/Container";

const JoinCommunityBanner = () => {
	return (
		<section className="py-12 bg-[#4ded54]">
			<Container className="flex items-center flex-col gap-8">
				<h2 className="text-[56px] text-[#1a1f29] leading-[84px] font-light md:text-[36px] md:leading-snug text-center">
					Join our communities
				</h2>
				<div className="w-1/2 pb-10 flex justify-between sm:w-full flex-wrap md:gap-4 md:justify-center">
					<a
						href="https://github.com/stronghandsblockchain"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-github.svg"
							width="100%"
							alt="Github icon"
						/>
					</a>
					<a
						href="https://discord.com/invite/WrA8TNXaa5"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-discord.svg"
							width="100%"
							alt="Discord icon"
						/>
					</a>
					<a
						href="https://t.me/StrongHands"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-telegram.svg"
							width="100%"
							alt="Telegram icon"
						/>
					</a>
					<a
						href="https://twitter.com/shndofficial"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-twitter.svg"
							width="100%"
							alt="Twitter icon"
						/>
					</a>
					<a
						href="https://www.reddit.com/r/stronghandsblockchain/"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-reddit.svg"
							width="100%"
							alt="Reddit icon"
						/>
					</a>
					<a
						href="https://www.facebook.com/shndchain"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-facebook.svg"
							width="100%"
							alt="Facebook icon"
						/>
					</a>
					<a
						href="https://www.instagram.com/stronghandsblockchain/"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-instagram.svg"
							width="100%"
							alt="Instagram icon"
						/>
					</a>
					<a
						href="https://www.linkedin.com/company/stronghands-blockchain/"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-linkedin.svg"
							width="100%"
							alt="Linkedin icon"
						/>
					</a>
					<a
						href="https://www.youtube.com/channel/UCazb7de4MDBFaWvnoCPvN-Q"
						className="w-12 xl:w-8 flex-shrink-0"
					>
						<img
							src="/icons/icon-youtube.svg"
							width="100%"
							alt="Youtube icon"
						/>
					</a>
				</div>
				<p className="flex gap-2 items-center text-[#2e3645]">
					<img src="/icons/tick.svg" alt="tick mark icon" />
					Smart Contract Audited
				</p>
			</Container>
		</section>
	);
};

export default JoinCommunityBanner;
