import clsx from "clsx";
import React from "react";

const Container = ({ className = "", children }) => {
	return (
		<section
			className={clsx(
				"max-w-new w-4/5 xl:max-w-[1116px] xl:w-full md:max-w-[696px] sm:max-w-[296px] mx-auto",
				className
			)}
		>
			{children}
		</section>
	);
};

export default Container;
