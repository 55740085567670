import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { shortenAddress } from "../../utils/ShortAdress";
import clsx from "clsx";
import { useState } from "react";
import styles from "./styles.module.css";

const Header = () => {
  const { currentAccount, connectWallet } = useContext(AppContext);
  const [modalOpen, setModalOpen] = useState(false);

  const handleHamburger = () => {
    if (modalOpen) {
      handleModalClose();
    } else {
      handleModalOpen();
    }
  };

  const handleModalClose = () => {
    document.getElementById("hamburger").classList.remove(styles["change"]);
    document.getElementById("modal").classList.add(styles["modalClose"]);

    setTimeout(() => {
      setModalOpen(false);
    }, 500);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
    document.getElementById("hamburger").classList.add(styles["change"]);
    document.getElementById("modal").classList.remove(styles["modalClose"]);
  };

  const handleRouteChange = (id) => {
    document.getElementById(id).scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  return (
    <header className="sticky top-0 bg-[#1A1F29] z-10">
      <div className="flex px-[25px] lg-header:px-4 max-w-new items-center mx-auto sm:w-max sm:gap-6">
        <div className="w-[232px] flex-shrink-0 flex-initial">
          <a href="/">
            <img src="/icons/stronghands-logo.svg" width="100%" alt="logo" />
          </a>
        </div>
        <nav className="flex flex-wrap items-stretch justify-end flex-1">
          <ul className="flex lg:hidden lg-header:text-sm">
            {/* <li className="text-[#ced4da] hover:opacity-70 px-3 py-[31px]">
              <a
                href="#aboutus"
                onClick={(event) => {
                  event.preventDefault();
                  handleRouteChange("aboutus");
                }}
              >
                About Us
              </a>
            </li> */}
            <li className="text-[#ced4da] hover:opacity-70 px-3 py-[31px]">
              <a
                href="https://bridge.stronghands.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                Bridge
              </a>
            </li>
            <li className="text-[#ced4da] hover:opacity-70 px-3 py-[31px]">
              <a
                href="https://market.stronghands.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                NFT Market
              </a>
            </li>
            <li className="text-[#ced4da] hover:opacity-70 px-3 py-[31px]">
              <a
                href="https://telegram.stronghands.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                Telegram
              </a>
            </li>
            <li className="text-[#ced4da] hover:opacity-70 px-3 py-[31px]">
              <a
                href="https://discord.stronghands.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                Discord
              </a>
            </li>
            <li className="text-[#ced4da] hover:opacity-70 px-3 py-[31px]">
              <a
                href="https://github.com/stronghandsblockchain/iSHND-NewSource/blob/main/Stronghands%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                ISHND Audit
              </a>
            </li>
          </ul>
          <div
            id="hamburger"
            className={styles.container}
            onClick={handleHamburger}
          >
            <div className={styles.bar1}></div>
            <div className={styles.bar2}></div>
            <div className={styles.bar3}></div>
          </div>
          {modalOpen && (
            <div
              id="modal"
              className={clsx(
                "fixed top-[86px] w-full left-0 border-t-4",
                styles.modal
              )}
            >
              <ul className="p-8">
                <li
                  className="text-[#ced4da] hover:opacity-70 px-7 py-3"
                  onClick={handleModalClose}
                >
                  <a
                    href="https://bridge.stronghands.io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Bridge
                  </a>
                </li>
                <li
                  className="text-[#ced4da] hover:opacity-70 px-7 py-3"
                  onClick={handleModalClose}
                >
                  <a
                    href="https://market.stronghands.io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    NFT Market
                  </a>
                </li>
                <li
                  className="text-[#ced4da] hover:opacity-70 px-7 py-3"
                  onClick={handleModalClose}
                >
                  <a
                    href="https://telegram.stronghands.io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Telegram
                  </a>
                </li>
                <li
                  className="text-[#ced4da] hover:opacity-70 px-7 py-3"
                  onClick={handleModalClose}
                >
                  <a
                    href="https://discord.stronghands.io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Discord
                  </a>
                </li>
                <li
                  className="text-[#ced4da] hover:opacity-70 px-7 py-3"
                  onClick={handleModalClose}
                >
                  <a
                    href="https://github.com/stronghandsblockchain/iSHND-NewSource/blob/main/Stronghands%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    ISHND Audit
                  </a>
                </li>
                {/* <li
                  className="text-[#ced4da] hover:opacity-70 px-7 py-3"
                  onClick={handleModalClose}
                >
                  <a
                    href="https://bscscan.com/token/0x1CC1aCa0DaE2D6c4A0e8AE7B4f2D01eAbbC435EE"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ISHND Explorer
                  </a>
                </li>
                <li
                  className="text-[#ced4da] hover:opacity-70 px-7 py-3"
                  onClick={handleModalClose}
                >
                  <a
                    href="https://www.coinexplorer.net/SHMN"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SHMN Explorer
                  </a>
                </li> */}
                <li></li>
              </ul>
            </div>
          )}
          {/* <button
            type="button"
            onClick={connectWallet}
            className="flex flex-row justify-center items-center my-5 bg-[#2952e3] p-3 rounded-lg cursor-pointer hover:bg-[#2546bd]"
          >
            <p className="text-base font-semibold text-white">
              {!currentAccount
                ? "Connect Wallet"
                : shortenAddress(currentAccount)}
            </p>
          </button> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
