import clsx from "clsx";
import React from "react";

const SocialIcons = ({ className = "" }) => {
	return (
		<div className={clsx("space-x-4 sm:space-x-2", className)}>
			<a href="https://github.com/stronghandsblockchain">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] transform hover:scale-125 fa-brands fa-github text-3xl"></i>
			</a>
			<a href="https://discord.com/invite/WrA8TNXaa5">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] transform hover:scale-125 fa-brands fa-discord text-3xl"></i>
			</a>
			<a href="https://t.me/StrongHands">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] transform hover:scale-125 fa-brands fa-telegram text-3xl"></i>
			</a>
			<a href="https://twitter.com/shndofficial">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] hover:scale-50 fa-brands fa-twitter text-3xl"></i>
			</a>
			<a href="https://www.reddit.com/r/stronghandsblockchain/">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] transform hover:scale-125 fa-brands fa-reddit-alien text-3xl"></i>
			</a>
			<a href="https://www.facebook.com/shndchain">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] transform hover:scale-125 fa-brands fa-facebook-f text-3xl"></i>
			</a>
			<a href="https://www.instagram.com/stronghandsblockchain/">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] transform hover:scale-125 fa-brands fa-instagram text-3xl"></i>
			</a>
			<a href="https://www.linkedin.com/company/stronghands-blockchain/">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] transform hover:scale-125 fa-brands fa-linkedin-in text-3xl"></i>
			</a>
			<a href="https://www.youtube.com/channel/UCazb7de4MDBFaWvnoCPvN-Q">
				<i className="text-[#E5E0D6] hover:text-[#4ded54] transform hover:scale-125 fa-brands fa-youtube text-3xl"></i>
			</a>
		</div>
	);
};

export default SocialIcons;
