import React, { useState, useEffect } from "react";

import { TailSpin } from "react-loader-spinner";
import { ethers } from "ethers";
import { contract_abi } from "./contract.abi";
import { formatUnits } from "ethers/lib/utils";
import BigNumber from "bignumber.js";

const NFTCard = ({
  videoUrl,
  name,
  hasLiked,
  CONTRACT_ADDRESS,
  currentAccount,
}) => {
  const [load, isLoad] = useState(false);
  const [supply, setSupply] = useState("0");
  const [total, setTotal] = useState("0");
  const [pricing, setPricing] = useState("");

  //contract
  const askContractToMintNft = async () => {
    isLoad(true);
    const { ethereum } = window;

    const getHigherGWEI = async () => {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const price = (await provider.getGasPrice()) * 2;

      return price;
    };

    const getPrice = async () => {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const token = new ethers.Contract(
        CONTRACT_ADDRESS,
        contract_abi.abi,
        provider
      );
      const price = await token.price();
      return price;
    };

    try {
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          contract_abi.abi,
          signer
        );

        const options = {
          value: getPrice(),
          gasPrice: getHigherGWEI(),
        };

        isLoad(false);

        return await connectedContract.buy(options);
      } else {
        console.log("Ethereum object doesn't exist!");
        isLoad(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSupply = async () => {
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const token = new ethers.Contract(
      CONTRACT_ADDRESS,
      contract_abi.abi,
      provider
    );
    const price = await token.totalSupply();
    const hardCap = formatUnits(price, 0);

    return setSupply(hardCap);
  };

  const getTotal = async () => {
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const token = new ethers.Contract(
      CONTRACT_ADDRESS,
      contract_abi.abi,
      provider
    );
    const price = await token.maxSupply();
    const hardCap = formatUnits(price, 0);

    return setTotal(hardCap);
  };

  const getPricing = async () => {
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const token = new ethers.Contract(
      CONTRACT_ADDRESS,
      contract_abi.abi,
      provider
    );
    const price = await token.price();
    const hardCap = formatUnits(price, 18);

    return setPricing(hardCap);
  };

  useEffect(() => {
    if (currentAccount) {
      getSupply();
      getTotal();
      getPricing();
    } else {
      console.log("error");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  return (
    <div className="p-4 mt-5 w-1/3 rounded-3xl border border-[#2E3645] md:w-3/5 sm:w-full md:mx-auto space-y-6">
      <video loop muted autoPlay className="rounded-3xl">
        <source src={videoUrl}></source>
      </video>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h3>{name}</h3>
          {supply} / {total}
          
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <img src="/icons/SHND-icon32px.svg" alt="shnd icon" />
            <span className="text-[10px]">StrongHands</span>
          </div>
          <div className="flex gap-2 p-2 border border-[#4DED54] rounded-xl">
            <img
              src="/icons/logos_ethereum.png"
              width="8px"
              height="12px"
              alt=""
            />

            {currentAccount !== "" ? (
              <>
                <button onClick={askContractToMintNft} className="text-xs">
                  <p> Mint for {pricing} ETH</p>
                </button>
              </>
            ) : (
              <p>Please connect Wallet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCard;
